<!--
 * @Description: 案例详情
 * @Author: zhang zhen
 * @Date: 2024-09-25 11:25:42
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-28 11:14:02
 * @FilePath: /page-sass/src/views/homePage/caseDetailPage.vue
-->
<template>
  <div class="caseDetailPage">
    <!-- 头部区域 -->
    <outSideHeader />
    <div class="header-cover" v-show="showHeader">
      <div class="tabBox">
        <a-tabs v-model="activeKey" @change="handleChangeTag">
          <a-tab-pane key="serviceContent" tab="服务内容"></a-tab-pane>
          <a-tab-pane key="serviceInfo" tab="服务详情"></a-tab-pane>
          <a-tab-pane key="successCase" tab="成功案例" v-if="successfulCase.length"></a-tab-pane>
          <a-tab-pane key="aboutShop" tab="关于商家"></a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="case-container">
      <h3 class="page-title">{{ baseInfo.title || '-' }}</h3>
      <!-- banner -->
      <a-carousel
        autoplay
        arrows
        :dots="false"
        style="width: 100%"
        ref="SwiperRef"
        :beforeChange="handleChangeTopSwiper"
      >
        <!-- <a slot="customPaging" slot-scope="props">
          <img class="slideImg" :src="bannerList[props.i]" />
        </a> -->
        <template #prevArrow>
          <img src="/banner_arrow_right.png" alt="" class="arrow" />
        </template>
        <template #nextArrow>
          <img src="/banner_arrow_left.png" alt="" class="arrow" />
        </template>
        <div class="bannerCover" v-for="item in bannerList">
          <img :src="item" />
        </div>
      </a-carousel>
      <ProductSwiper ref="ProductSwiperRef" :productList="bannerList" @changeSwiper="handleChangeSwiper" />
      <div class="tabBox">
        <a-tabs v-model="activeKey" @change="handleChangeTag">
          <a-tab-pane key="serviceContent" tab="服务内容"></a-tab-pane>
          <a-tab-pane key="serviceInfo" tab="服务详情"></a-tab-pane>
          <a-tab-pane key="successCase" tab="成功案例" v-if="successfulCase.length"></a-tab-pane>
          <a-tab-pane key="aboutShop" tab="关于商家"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="serviceCover" id="serviceContent">
        <div class="part-title">
          <span>服务内容</span>
        </div>
        <div class="info-area">
          <div class="table-title">
            <span class="title">项目开工</span>
          </div>
          <table class="info-table">
            <tr class="row-box">
              <td class="typeA" rowspan="3">提供服务设计师</td>
              <td class="typeB">新锐设计师</td>
              <td class="typeC">{{ baseInfo.vigorousDesigner || '-' }}</td>
            </tr>
            <tr class="row-box">
              <td class="typeB">资深设计/美术指导</td>
              <td class="typeC">{{ baseInfo.seniorDesigner || '-' }}</td>
            </tr>
            <tr class="row-box">
              <td class="typeB">设计总监</td>
              <td class="typeC">{{ baseInfo.designDirector || '-' }}</td>
            </tr>
            <tr class="row-box">
              <td class="typeA" rowspan="2">提供服务方式</td>
              <td class="typeB">专属服务对接群</td>
              <td class="typeC">{{ baseInfo.exclusiveGroup || '-' }}</td>
            </tr>
            <tr class="row-box">
              <td class="typeB">全程一对一顾问服务</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" v-if="baseInfo.individualService" />
                <span v-else>-</span>
              </td>
            </tr>
          </table>
          <div class="table-title">
            <span class="title">完成初稿设计</span>
          </div>
          <table class="info-table">
            <tr class="row-box">
              <td class="typeD">初稿交付时间</td>
              <td class="typeC">{{ baseInfo.firstDeliveryDays || '-' }}</td>
            </tr>
            <tr class="row-box">
              <td class="typeD">初稿方案数量</td>
              <td class="typeC">
                {{ baseInfo.firstDraftCaseNum || '-' }}
              </td>
            </tr>
          </table>
          <div class="table-title">
            <span class="title">稿件细化修改</span>
          </div>
          <table class="info-table">
            <tr class="row-box">
              <td class="typeD">供修改次数</td>
              <td class="typeC">{{ baseInfo.provideUpdateTimes || '-' }}</td>
            </tr>
            <tr class="row-box">
              <td class="typeD">每次修改所需时长</td>
              <td class="typeC">
                {{ baseInfo.updateDays || '-' }}
              </td>
            </tr>
          </table>
          <div class="table-title">
            <span class="title">终稿交付</span>
            <!-- <div class="last-right">
              <span class="label">验收后付款比例：</span>
              <span class="num">100%</span>
            </div> -->
          </div>
          <table class="info-table">
            <tr class="row-box">
              <td class="typeD">原创手绘插画</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" v-if="baseInfo.originalDrwan" />
                <span v-else>-</span>
              </td>
            </tr>
            <!-- <tr class="row-box">
              <td class="typeD">包装3D建模</td>
              <td class="typeC">
                -
              </td>
            </tr> -->
            <tr class="row-box">
              <td class="typeD">主画面延展设计</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" v-if="baseInfo.extensionDesign" />
                <span v-else>-</span>
              </td>
            </tr>
            <tr class="row-box">
              <td class="typeD">制作工艺与材质规范</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" v-if="baseInfo.screenDesign" />
                <span v-else>-</span>
              </td>
            </tr>
          </table>
          <table class="info-table">
            <tr class="row-box">
              <td class="typeA" rowspan="4">包含交付文件</td>
              <td class="typeB">多角度高清效果图</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" />
              </td>
            </tr>
            <tr class="row-box">
              <td class="typeB">刀版印刷图</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" />
              </td>
            </tr>
            <tr class="row-box">
              <td class="typeB">可打印文件</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" />
              </td>
            </tr>
            <tr class="row-box">
              <td class="typeB">源文件</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" />
              </td>
            </tr>
          </table>

          <table class="info-table" style="border-bottom: 1px solid #f1f1f1">
            <tr class="row-box">
              <td class="typeD">提供版权转让协议</td>
              <td class="typeC">
                <img src="/hotDesigner/pass_icon.png" alt="" class="success" />
              </td>
            </tr>
            <tr class="row-box">
              <td class="typeD">交付周期</td>
              <td class="typeC">
                {{ baseInfo.deliveryCycle || '-' }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="serviceCover" id="serviceInfo">
        <div class="part-title">
          <span>服务详情</span>
        </div>
        <div class="info-area">
          <div class="content-area-inline">
            <div class="label">服务区域：全国</div>
          </div>
          <div class="content-area-inline">
            <div class="label">服务内容全部包含：</div>
            <div class="text-content">
              {{ serviceDesc || '暂无内容' }}
            </div>
          </div>
          <div class="tags-area">
            <div class="tag-item" v-for="i in tagList">
              {{ i }}
            </div>
          </div>
        </div>
      </div>
      <div class="serviceCover" id="successCase" v-if="successfulCase.length">
        <div class="part-title">
          <span>成功案例</span>
        </div>
        <div class="successfulCase">
          <img v-for="i in successfulCase" :key="i" :src="i" alt="" class="banner-img" />
          <a-empty
            class="empty-case"
            image="/hotDesigner/emptyCase.png"
            description="暂无成功案例"
            v-if="!successfulCase.length"
          ></a-empty>
        </div>
      </div>
      <div class="serviceCover" id="aboutShop">
        <div class="part-title">
          <span>关于商家</span>
        </div>
        <div class="info-area">
          <!-- 公司信息 -->
          <div class="company-top-info">
            <img :src="baseInfo.shopAvatar || '/avatar.png'" alt="头像" class="avatar" />
            <div class="rest-company-info">
              <div class="main-info">
                <h3 class="shopName">{{ baseInfo.shopTitle || '-' }}</h3>
                <!-- <div class="header-tag-main">
                  <div class="tag-box-item bg_1">二星服务商</div>
                  <div class="tag-box-item bg_2">2023年设计行业十佳服务商</div>
                  <div class="tag-box-item bg_3">2023年品牌设计十强服务商</div>
                  <div class="tag-box-item bg_4">保证金 1.2万</div>
                  <div class="tag-box-item bg_5">入选包装设计</div>
                </div> -->
              </div>
              <!-- <a-button type="primary" class="action-btn">
                <img src="/hotDesigner/message-3-line.png" alt="消息" class="message-me" />
                <span>立即咨询</span>
              </a-button> -->
            </div>
          </div>
          <div class="number-areas">
            <div class="number-areas-item">
              <span class="num">{{ baseInfo.qualityScore || 0 }}</span>
              <span class="label">服务质量评分</span>
            </div>
            <div class="number-areas-item">
              <span class="num">{{ baseInfo.transactionsNum || 0 }}</span>
              <span class="label">近半年成交</span>
            </div>
            <!-- <div class="number-areas-item">
              <span class="num">{{ baseInfo.serviceForNum || 0 }}</span>
              <span class="label">服务雇主数</span>
            </div> -->
            <div class="number-areas-item">
              <span class="num">{{ baseInfo.finishRate || 0 }}</span>
              <div class="info">
                <span class="label">项目完成率 </span>
                <a-tooltip placement="top">
                  <template slot="title"> 项目完成率 </template>
                  <img src="/hotDesigner/info_1.png" alt="" class="star-icon" />
                </a-tooltip>
              </div>
            </div>
          </div>
          <p class="text-context">
            {{ baseInfo.shopInfo || '-' }}
          </p>
          <template v-if="shopDesc && shopDesc.length">
            <div class="part-title-new">团队介绍</div>
            <img v-for="i in shopDesc" :key="i" :src="i" alt="" class="coverBanner" />
          </template>
          <template v-if="shopHonor && shopHonor.length">
            <div class="part-title-new">荣誉资质</div>
            <div class="honor-container" v-viewer="{movable: false}">
              <img v-for="(i, index) in shopHonor" :key="i" :src="i" alt="" class="cover-box" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import outSideHeader from '@/components/tools/outSideHeader.vue'
import { getAction } from '@/api/manage'
import ProductSwiper from './modules/productSwiper.vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)
const handleScroll = (that) => {
  let menuItems = []
  if (that.hasCase) {
    menuItems = [
      { id: 'serviceContent', label: '服务内容' },
      { id: 'serviceInfo', label: '服务详情' },
      { id: 'successCase', label: '成功案例' },
      { id: 'aboutShop', label: '关于商家' },
    ]
  } else {
    menuItems = [
      { id: 'serviceContent', label: '服务内容' },
      { id: 'serviceInfo', label: '服务详情' },
      { id: 'aboutShop', label: '关于商家' },
    ]
  }
  const scrollPos = window.scrollY + 132
  // const showTagEl = document.getElementById('serviceContent')
  // console.log(window.scrollY, showTagEl.offsetTop)
  if (window.scrollY >= 913) {
    that.showHeader = true
  } else {
    that.showHeader = false
  }
  menuItems.forEach((item) => {
    const section = document.getElementById(item.id)

    if (section && scrollPos >= section.offsetTop) {
      that.activeKey = item.id
    }
  })
}

function splitArrayIntoPairs(arr) {
  // 创建一个空数组来存放结果
  let result = []

  // 遍历数组，从索引0开始，步长为2
  // 注意：由于我们每次处理两个元素，所以需要确保索引小于数组长度减1
  for (let i = 0; i < arr.length - 1; i += 2) {
    // 将当前索引及其后一个索引的元素组成一个子数组，并添加到结果数组中
    result.push([arr[i], arr[i + 1]])
  }

  return result
}

export default {
  components: {
    outSideHeader,
    ProductSwiper,
  },
  data() {
    return {
      bannerList: [],
      baseInfo: {},
      activeKey: 'serviceContent',
      showHeader: false,
      serviceDesc: '',
      tagList: [],
      successfulCase: [],
      shopScoreList: [],
      shopDesc: [],
      shopHonor: [],
    }
  },
  created() {
    let id = this.$route.query.id
    id && this.handleLoadData(id)
  },
  destroyed() {
    const that = this

    window.removeEventListener('scroll', () => handleScroll(that))
  },
  methods: {
    handleChangeTopSwiper(from, to) {
      let rowIndex = to
      this.$refs.ProductSwiperRef.handleJumpIndex(rowIndex)
    },
    handleScrollInit() {
      const that = this

      window.addEventListener('scroll', () => handleScroll(that))
    },
    handleChangeSwiper(index) {
      this.$refs.SwiperRef.goTo(index, false)
    },
    handleChangeTag() {
      // 锚点跳转
      // const element = document.getElementById(this.activeKey);
      // element.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动
      const element = document.getElementById(this.activeKey)
      // 注意：element.offsetTop 获取的是相对于其offsetParent的顶部距离
      // 如果你想要滚动到元素顶部正好对齐视窗顶部，应该直接使用 element.offsetTop
      // 但如果页面上有复杂的布局或定位，可能还需要考虑其他因素，如滚动条位置等
      window.scrollTo({
        top: element.offsetTop - 120, // 滚动到元素的顶部
        behavior: 'smooth', // 平滑滚动
      })
    },
    handleViewImage(index) {
      this.$viewerApi({
        images: this.shopHonor,
        initialViewIndex: index
      })
    },
    handleLoadData(id) {
      getAction(`/businessDesigner/detail/${id}`).then((res) => {
        const { success, data } = res
        if (success) {
          // this.list = data
          const { banner, serviceDetail, shopDesc, tags, shopHonor, successfulCase, shopScore } = data
          this.bannerList = banner || []
          this.baseInfo = data
          // 服务内容全部包含:
          this.serviceDesc =
            serviceDetail && serviceDetail.includes('服务内容全部包含: ')
              ? serviceDetail.replace('服务内容全部包含: ', '')
              : serviceDetail
          this.tagList = tags || []
          this.successfulCase = successfulCase || []
          this.shopScoreList = shopScore ? splitArrayIntoPairs(shopScore) : []
          this.shopDesc = shopDesc || []
          this.shopHonor = shopHonor || []
          this.hasCase = !!successfulCase.length
          this.handleScrollInit()
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.caseDetailPage {
  background: #f3f5f9;
  padding-top: 56px;
  min-height: 100vh;
  .case-container {
    padding: 40px 0 160px;
    margin: 0 auto;
    width: 1112px;
    overflow: hidden;
    .page-title {
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 33.6px;
      text-align: left;
      color: #131212;
      margin-bottom: 30px;
    }
    .bannerCover {
      width: 1112px;
      height: 600px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16/9;
        border-radius: 8px;
      }
    }
    .slideImg {
      width: 148px;
      height: 86px;
      border-radius: 6px;
    }
    ::v-deep .ant-carousel {
      .slick-arrow {
        display: none !important;
      }
      &:hover {
        .slick-arrow {
          display: block !important;
        }
      }
    }
    ::v-deep .ant-carousel .slick-prev {
      left: 0;
      z-index: 2;
    }
    ::v-deep .ant-carousel .slick-next {
      right: 0;
      z-index: 2;
    }
    img.arrow {
      width: 38px;
      height: 36px;
    }
  }

  .tabBox {
    margin-top: 25px;
    background: #fff;
    border-radius: 8px;
    padding: 10px 24px 0;
    box-sizing: border-box;
    ::v-deep .ant-tabs-tab {
      padding: 12px 0 20px;
      font-size: 16px;
    }
    ::v-deep .ant-tabs-bar {
      margin-bottom: 0;
      border-color: transparent;
    }
  }
  .header-cover {
    position: fixed;
    width: 100%;
    top: 56px;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 1px 8px 0px #0000000a;
    border-top: 1px solid #f1f1f1;
    z-index: 23;
    .tabBox {
      margin-top: 0;
      width: 1112px;
    }
  }
  .part-title {
    display: flex;
    align-items: center;
    padding: 30px 0;
    &::before {
      display: block;
      content: '';
      width: 13px;
      height: 32px;
      margin-right: 10px;
      background: url('/hotDesigner/line_tag.png');
      background-size: 100% 100%;
      margin-top: 10px;
    }
    span {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #000;
    }
  }
  .serviceCover {
    width: 100%;

    .info-area {
      background: #fff;
      border-radius: 8px;
      padding: 35px 35px 30px;
      box-sizing: border-box;
      .table-title {
        width: 100%;
        background: linear-gradient(180deg, #fffaf6 0%, rgba(255, 255, 255, 0.46) 100%);
        height: 41px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid #f1f1f1;
        border-bottom: none;
        .title {
          font-family: Helvetica Neue;
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;
          text-align: left;
          color: #333;
        }
        .last-right {
          display: flex;
          align-items: center;
          span.label {
            font-family: Helvetica Neue;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            text-align: left;
            color: #666;
          }
          span.num {
            font-family: Helvetica Neue;
            font-size: 13px;
            font-weight: 700;
            line-height: 14px;
            text-align: left;
            color: #ff6900;
          }
        }
      }
      .info-table {
        width: 100%;
        .row-box {
          td.typeA {
            width: 262px;
            padding: 16px;
            box-sizing: border-box;
            font-family: Helvetica Neue;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
            text-align: left;
            color: #333;
            border: 1px solid #f1f1f1;
            border-right: none;
            border-bottom: none;
          }
          td.typeB {
            padding: 7px 8px;
            width: 262px;
            box-sizing: border-box;
            color: #333;
            font-size: 13px;
            line-height: 20px;
            border: 1px solid #f1f1f1;
            border-right: none;
            border-bottom: none;
          }
          td.typeC {
            background: #f5faff;
            padding: 7px 8px;
            font-size: 13px;
            line-height: 20px;
            color: #333;
            border: 1px solid #f1f1f1;
            border-bottom: none;
          }
          td.typeD {
            padding: 7px 16px;
            width: 524px;
            box-sizing: border-box;
            color: #333;
            font-size: 13px;
            line-height: 20px;
            border: 1px solid #f1f1f1;
            border-right: none;
            border-bottom: none;
          }
          .success {
            width: 16px;
            height: 16px;
          }
        }
      }
      .content-area-inline {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        .label {
          color: #131212;
          font-size: 14px;
          line-height: 20px;
        }
        .text-content {
          line-height: 24px;
          color: #666666;
        }
      }
      .tags-area {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 14px 14px;
        .tag-item {
          padding: 0 8px;
          line-height: 24px;
          font-size: 12px;
          color: #ff6026;
          background-color: #fff2ea;
          border-radius: 2px;
        }
      }
      .number-areas {
        width: 680px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 88px;
          height: 40px;
          .num {
            font-family: Helvetica Neue;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: #ff6026;
          }
          .label {
            font-family: Helvetica Neue;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            text-align: center;
            color: #999;
          }
          .info {
            display: flex;
            align-items: center;
            .star-icon {
              width: 14px;
              height: 14px;
              cursor: pointer;
              margin-left: 4px;
            }
          }
        }
      }
      .coverBanner {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin: 24px 0;
      }
      .text-context {
        margin-top: 20px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #666;
        margin-bottom: 0;
      }
      .part-title-new {
        background: linear-gradient(90deg, #ffeadb 0%, rgba(255, 227, 207, 0.01) 100%);
        padding: 8px 12px;
        box-sizing: border-box;
        font-family: Helvetica Neue;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: #ff6026;
        margin-top: 24px;
      }
      .honor-container {
        margin-top: 24px;
        display: flex;
        grid-gap: 10px 10px;
        img.cover-box {
          width: 220px;
          height: 148px;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      .company-top-info {
        display: flex;
        align-items: center;
        padding-bottom: 25px;
        border-bottom: 1px dashed #eeeeee;
        margin-bottom: 26px;
        .avatar {
          width: 56px;
          height: 56px;
          margin-right: 12px;
          border-radius: 50%;
        }
        .rest-company-info {
          height: 68px;
          display: flex;
          justify-content: space-between;
          flex: 1 0 0;
          min-width: 0;
          .main-info {
            height: 100%;
            width: 486px;
            display: flex;
            align-items: center;
            h3.shopName {
              color: #333;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
              text-align: left;
              margin-bottom: 12px;
            }
            .header-tag-main {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              grid-gap: 8px 6px;
              .tag-box-item {
                // height: 16px;
                // line-height: 16px;
                text-align: center;
                padding: 1px 4px;
                font-size: 12px;
                color: #fff;
              }
              .bg_1 {
                background: #1a9efd;
              }
              .bg_2 {
                background: #ff6901;
              }
              .bg_3 {
                background: #0652d9;
              }
              .bg_4 {
                background: #01a04b;
              }
              .bg_5 {
                background: #fff7e7;
                color: #97825b;
              }
            }
          }
          .action-btn {
            width: 110px;
            height: 36px;
            font-size: 14px;
            font-weight: 600;
            margin-top: 16px;
            img.message-me {
              width: 16px;
              height: 16px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .successfulCase {
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      img.banner-img {
        width: 100%;
        height: auto;
        margin-bottom: 32px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .empty-case {
        margin: 60px auto;
        ::v-deep .ant-empty-image {
          height: 180px;
        }
        ::v-deep .ant-empty-description {
          color: #999999;
        }
      }
    }
  }
}
</style>
